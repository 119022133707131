import React from 'react';
import { IPostProps } from '../../templates/post';
import { PostCard } from '../shared';
import { CEO, CTO } from '../../utils/authorConfig';

interface ISuggestedPostsProps {
  suggestedPosts: IPostProps[];
}

const SuggestedPosts = ({ suggestedPosts }: ISuggestedPostsProps) => (
  <div className="max-w-lg w-full mx-auto">
    <h2 className="font-bold text-4xl text-white mt-12 mb-2 mx-4">
      Suggested Posts
    </h2>
    <div className="flex w-full justify-center flex-wrap">
      {suggestedPosts.map((node: IPostProps) => {
        const primaryColour =
          node.authors[0].slug === CEO
            ? 'border-pink-500 text-pink-500'
            : node.authors[0].slug === CTO
            ? 'border-paleBlue-500 text-paleBlue-500'
            : 'border-white text-white';

        return (
          <PostCard key={node.id} post={node} primaryColour={primaryColour} />
        );
      })}
    </div>
  </div>
);

export default SuggestedPosts;
