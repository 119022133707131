import React from 'react';
import tailwind from '../../tailwind.config';

const IconProfile = () => (
  <svg
    viewBox="0 0 94 94"
    width="100%"
    fill={tailwind.theme.extend.colors.purple[900]}
  >
    <circle cx={47} cy={37.3} r={16.7} transform="translate(0 -.7)" />
    <path d="M47 .7C21.5.7.7 21.4.7 47 .7 72.6 21.5 93.3 47 93.3A46.4 46.4 0 0093.3 47 46.4 46.4 0 0047 .7m27.2 75.5C70.8 67.1 63.4 57.4 47 57.4c-16.3 0-23.8 9.7-27.2 18.8A40.02 40.02 0 017 47a39.99 39.99 0 1180 0c0 11.5-5 21.9-12.8 29.2" />
  </svg>
);

export default IconProfile;
