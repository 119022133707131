import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Prism from 'prismjs';

import { Layout } from '../components/shared';
import { MetaData } from '../components/shared/meta';
import PostBanner from '../components/postPage/PostBanner';
import Tags from '../components/shared/Tags';
import SharePost from '../components/postPage/SharePost';
import SuggestedPosts from '../components/postPage/SuggestedPosts';
import { ITagProps } from './tag';
import { IAuthorProps } from './author';
import { FixedObject } from 'gatsby-image';

export const postQuery = graphql`
  query($slug: String!, $authorSlug: String!) {
    ghostAuthor(slug: { eq: $authorSlug }) {
      ...GhostAuthorFields
      profileImageSharp {
        childImageSharp {
          avatar: fixed(width: 60, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
      featureImageSharp {
        childImageSharp {
          full: fluid(maxWidth: 558, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
          thumb: fixed(width: 265, height: 298) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    similarPosts: allGhostPost(
      filter: {
        tags: { elemMatch: { name: { in: ["CEO", "CTO"] } } }
        slug: { ne: $slug }
      }
      limit: 2
    ) {
      edges {
        node {
          ...GhostPostFields
          featureImageSharp {
            childImageSharp {
              full: fluid(maxWidth: 558, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
              thumb: fixed(width: 265, height: 298) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    latestPosts: allGhostPost(
      filter: { slug: { ne: $slug } }
      sort: { order: DESC, fields: [published_at] }
      limit: 2
    ) {
      edges {
        node {
          ...GhostPostFields
          featureImageSharp {
            childImageSharp {
              full: fluid(maxWidth: 558, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
              thumb: fixed(width: 265, height: 298) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;

export interface IPostProps {
  id?: string;
  slug?: string;
  title?: string;
  date?: string;
  created_at?: string;
  html?: string;
  feature_image?: string;
  published_at?: string;
  updated_at?: string;
  meta_title?: string;
  meta_description?: string;
  primary_author?: {};
  tags?: ITagProps[];
  primaryTag?: {
    name: string;
  };
  og_title?: string;
  og_description?: string;
  twitter_title?: string;
  twitter_description?: string;
  excerpt?: string;
  authors: IAuthorProps[];
  featureImageSharp?: {
    childImageSharp: {
      thumb?: FixedObject;
      full?: FixedObject;
    };
  };
}

export interface IPostNodeProps {
  node: IPostProps;
}

interface IPostTemplateProps {
  data: {
    ghostAuthor: IAuthorProps;
    ghostPost: IPostProps;
    similarPosts: {
      edges: IPostNodeProps[];
    };
    latestPosts: {
      edges: IPostNodeProps[];
    };
  };
  location: {
    pathname: string;
  };
}

const Post = ({ data, location }: IPostTemplateProps) => {
  const post = data.ghostPost;
  const author = data.ghostAuthor;
  // new array that will hold the data returned from the query for similar and latest posts
  const suggestedPostsArray: IPostProps[] = [];

  // push data from queries into one single array
  data.similarPosts.edges.forEach((edge) =>
    suggestedPostsArray.push(edge.node),
  );
  data.latestPosts.edges.forEach((edge) => suggestedPostsArray.push(edge.node));

  // new array that will contain distinct suggestedPosts (avoiding duplicates)
  const suggestedPosts: IPostProps[] = [];
  const map = new Map();

  // for each item in the array, check that it doesn't exists already. If it doesn't exist already, push into suggestedPosts
  // suggestedPosts can then be passed into SuggestedPosts component which will now always render two distinct suggestions,
  // starting with similarly tagged posts and falling back to the latest posts but avoiding duplicates
  suggestedPostsArray.forEach((post) => {
    if (!map.has(post.id)) {
      map.set(post.id, true);
      suggestedPosts.push(post);
    }
  });

  useEffect(() => {
    Prism.highlightAll();
  });

  return (
    <>
      <MetaData data={data} location={location} />
      <Helmet></Helmet>
      <Layout>
        <article className="max-w-md mx-4 bg-white rounded-lg overflow-hidden w-full">
          <PostBanner author={author} post={post} />
          <section className="flex p-12 justify-center md:flex-col sm:p-4">
            <div
              className="max-w-postBody w-full text-xl"
              id="innerHTML"
              dangerouslySetInnerHTML={{ __html: post.html || '' }}
            />
            {/* <aside className="text-purple-500 pl-8 w-64 flex-shrink-0 md:w-full md:justify-center md:mt-4">
              <section className="flex flex-col mb-4">
                <h3 className="font-semibold text-3xl mb-2">Tags</h3>
                <Tags inline={false} clickable={true} post={post} />
              </section>
              <section className="flex flex-col mb-4">
                <h3 className="font-semibold text-3xl mb-2">Share</h3>
                <SharePost post={data?.ghostPost} />
              </section>
            </aside> */}
          </section>
        </article>
        <SuggestedPosts suggestedPosts={suggestedPosts.slice(0, 2)} />
      </Layout>
    </>
  );
};

export default Post;
