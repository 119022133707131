import React from 'react';

import { IPostProps } from '../../templates/post';
import formatDate from '../../utils/formatDate';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import IconProfile from '../../svgs/Iconprofile';
import { IAuthorProps } from '../../templates/author';
import GatsbyImage from 'gatsby-image';
import { CEO, CTO } from '../../utils/authorConfig';

interface IPostBannerProps {
  post: IPostProps;
  author: IAuthorProps;
}

export const DEFAULT_FEATURE_IMAGE_QUERY = graphql`
  query {
    mountain: file(base: { eq: "mountain.jpg" }) {
      childImageSharp {
        full: fluid(maxWidth: 558, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const PostBanner = ({ author, post }: IPostBannerProps) => {
  const defaultImage = useStaticQuery(DEFAULT_FEATURE_IMAGE_QUERY);
  const isCEO = author.slug === CEO;
  const isCTO = author.slug === CTO;
  const title = isCEO ? 'CEO, Yozobi' : isCTO ? 'CTO, Yozobi' : 'Yozobi';
  const featureImg =
    post.featureImageSharp?.childImageSharp?.full ||
    defaultImage.mountain?.childImageSharp?.full;

  return (
    <header className="flex">
      <div className="w-1/2 text-purple-900 p-12 sm:p-6 md:w-full md:text-center">
        <h1 className="text-5xl text-purple-900 italic mt-12 mb-16 md:m-6">
          {post.title}
        </h1>
        <div className="flex items-center md:justify-center">
          {author.profileImageSharp ? (
            <GatsbyImage
              className="mr-8 rounded-full w-16 cursor-pointer flex-shrink-0"
              alt={author.name}
              fixed={author.profileImageSharp.childImageSharp.avatar}
              // @ts-ignore
              onClick={() => navigate(`/author/${author.slug}`)}
            />
          ) : (
            <div
              className="mr-8 rounded-full w-16 cursor-pointer"
              title={`View ${author.name}'s profile`}
              onClick={() => navigate(`/author/${author.slug}`)}
            >
              <IconProfile />
            </div>
          )}

          <div className="flex flex-col">
            <h2
              className="font-semibold text-2xl cursor-pointer"
              title={`View ${author.name}'s profile`}
              onClick={() => navigate(`/author/${author.slug}`)}
            >{`${author.name}, ${title}`}</h2>
            <div>{formatDate(post.created_at)}</div>
          </div>
        </div>
      </div>
      {featureImg && (
        <div className="flex w-1/2 md:hidden">
          <Img fluid={featureImg} className="w-full" />
        </div>
      )}
    </header>
  );
};

export default PostBanner;
